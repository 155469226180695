<template>
  <div class="w-full md:w-1/2 lg:w-1/4 mb-5">
    <div class="rounded-xl m-h-64 pr-2 flex flex-col transform transition duration-300">
      <div class="rounded-lg p-4 bg-brand-button flex flex-col mb-2" style="background-image: url('https://dl.globalchataddon.de/images/shop_background.png'); background-repeat: no-repeat;
    background-size: 100%;">
        <div class="minecraft text-dark_red text-red text-gold text-yellow text-dark_green text-green text-aqua text-dark_aqua text-dark_blue text-blue text-light_purple text-dark_purple text-white text-gray text-dark_gray text-black">
          <p v-if="this.user.name !== undefined" class="flex items-center text-sm text-white">
            <span class="text-dark_gray">[</span><span class="text-gray">Hypixel.net</span><span class="text-dark_gray">]</span> <img v-if="this.items.emoteChatUrl !== ''" class="w-8 h-8" :src="this.items.emoteChatUrl"/> <span :style="computedTextStyle" :class="['px-1', this.items.bold ? 'font-bold' : '', this.items.italic ? 'italic' : '']">{{ this.user.name}}</span> <span class="px-1 text-dark_gray">»</span>
          </p>
          <p v-else class="text-sm text-white flex items-center">
            <span class="text-dark_gray">[</span><span class="text-gray">Hypixel.net</span><span class="text-dark_gray">]</span> <img v-if="this.items.emoteChatUrl !== ''" class="w-8 h-8" :src="this.items.emoteChatUrl"/> <span :style="computedTextStyle" :class="['px-1', this.items.bold ? 'font-bold' : '', this.items.italic ? 'italic' : '']">YourName</span> <span class="px-1 text-dark_gray">»</span>
          </p>
        </div>
      </div>
      <div :class="[
                  this.items.limited ? 'bg-brand-gold' : 'bg-brand-button',
                ]" class="rounded-lg p-4 flex flex-col">
        <div>
          <h5 class="text-white text-2xl font-bold leading-none">
            {{ this.items.name }}
          </h5>
          <span class="text-xs text-gray-100 leading-none">{{
              this.items.description
            }}</span>
        </div>
        <div class="flex items-center">
          <a class="flex items-center justify-centerpy-2">
            <img src="@/assets/images/coin.svg"/>
            <span class="px-1 font-medium">{{ this.items.price }} GCoins</span>
          </a>
          <button v-if="authorized"
                  v-on:click="toggleModal()"
                  :class="[
                  this.items.limited ? 'bg-brand-gold_button hover:text-gold' : 'bg-blue-800 hover:text-blue',
                ]" class="rounded-full text-white hover:bg-white hover:shadow-xl focus:outline-none w-10 h-10 flex ml-auto transition duration-300"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-current m-auto"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </button>
          <button v-else v-on:click="register()"
                  :class="[
                  this.items.limited ? 'bg-brand-gold_button hover:text-gold' : 'bg-blue-800 hover:text-blue',
                ]"
                  class="rounded-full text-white hover:bg-white hover:shadow-xl focus:outline-none w-10 h-10 flex ml-auto transition duration-300"
          ><svg                 xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="stroke-current m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
          </svg></button>
        </div>
      </div>
    </div>
  </div>

  <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
        >&#8203;</span
        >
        <div
            class="inline-block bg-brand align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-blue-500">
                  Purchase confirmation
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-100">
                    Are you sure you want to buy it?
                  </p>
                  <p class="text-sm text-gray-100">
                    You get the prefix "{{ this.items.name }}" for
                    {{ this.items.price }} GCoins!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-1 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                v-on:click="buy()"
                class="h-10 bg-brand-button text-white rounded-md px-4 py-2 m-2 focus:outline-none focus:shadow-outline"
            >
              Confirm
            </button>
            <button
                type="button"
                v-on:click="toggleModal()"
                class="h-10 bg-red-500 text-white rounded-md px-4 py-2 m-2 focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="opacity-75 fixed inset-0 z-40 bg-black"></div>
</template>

<script>
import router from "../router";

export default {
  name: "ProductComponent",
  props: {
    items: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    creator: {
      type: String,
      required: true,
    },
    authorized: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    register: function () {
      router.push("/dashboard")
    },
    async buy() {
      if (this.items.price > this.user.coins) {
        this.$notify(
            {
              title: "Error",
              text: "You have too few GCoins!",
              type: "warning",
              group: "errorNotification",
            },
            4000
        );
      } else {
        await fetch(
            "https://api.globalchataddon.de/dashboard/shop/buy/" + this.items.id,
            {
              method: "POST",
              headers: {
                token: this.$cookies.get("GlobalChat-Login"),
                creator: this.creator
              },
            }
        ).then((response) => {
          if (response.status === 200) {
            response.json().then((json) => {
              if (json.status !== undefined) {
                switch (json.status) {
                  case 1: {
                    this.$notify(
                        {
                          title: "Error",
                          text: "You already have this prefix!",
                          type: "warning",
                          group: "errorNotification",
                        },
                        4000
                    );
                    break;
                  }
                  case 2: {
                    this.$notify(
                        {
                          title: "Error",
                          text: "You have too few GCoins!",
                          type: "warning",
                          group: "errorNotification",
                        },
                        4000
                    );
                    break;
                  }
                  case 3: {
                    this.$notify(
                        {
                          title: "Successful",
                          text: "You have successfully purchased the prefix.",
                          type: "warning",
                          group: "successfulNotification",
                        },
                        4000
                    )
                    break;
                  }
                }
              } else {
                //FEHLER
              }
            });
          } else {
            console.log("Fehler aufgetreten!");
          }
        });
      }
      this.toggleModal();
    },
  },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        computedTextStyle() {
            if (!this.items.color.includes(":")) {
                return "color: " + this.items.color;
            }

            const hexToRgb = hex => {
                // Entferne das führende #
                hex = hex.replace(/^#/, '');

                // Teile den Hexadezimalwert in seine Komponenten auf (r, g, b)
                const bigint = parseInt(hex, 16);
                const r = (bigint >> 16) & 255;
                const g = (bigint >> 8) & 255;
                const b = bigint & 255;

                return [r, g, b];
            };

            const colors = this.items.color.split(":").map(hexToRgb).map(color => `rgb(${color.join(", ")})`);
            return `background-image: linear-gradient(to right, ${colors.join(", ")}); background-clip: text; -webkit-background-clip: text; color: transparent;`;
        },
    }
  };
</script>
<template>
  <div class="rounded-t mb-0 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h1 class="mb-6 text-5xl font-bold text-white leading-snug">Shop</h1>
      </div>

      <div v-if="this.user.uuid !== undefined"  class="relative w-full max-w-full flex-grow flex-1 text-right">
        <div
            class="p-2 bg-brand-button items-center text-indigo-100 leading-none rounded-full flex inline-flex"
        >
          <span
              class="flex rounded-full bg-blue-800 uppercase px-2 py-1 text-xs font-bold mr-3"
          >Creator-Code</span
          >
          <span class="font-semibold mr-2 text-left flex-auto">
            <input type="text" placeholder="Name" v-model="creator"
                   class="items-center font-semibold text-white placeholder-white transition duration-500 ease-in-out transform bg-brand-button hover:bg-blueGray-900 focus:ring focus:outline-none"
            />
          </span>
        </div>
      </div>

    </div>
  </div>
  <div v-if="this.user.uuid !== undefined" class="bg-brand-white10 rounded-xl">
    <div class="mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex">
            <img v-if="this.user.uuid !== undefined" :src="`https://render.hdskins.de/users/skin/${this.user.uuid}/face`" class="mx-auto object-cover h-10 w-10"/>
          </span>
          <p class="ml-3 font-medium text-white truncate">
            <span> Welcome {{ this.user.name }} </span>
          </p>
        </div>
        <div
            class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <a class="flex items-center justify-center px-4 py-2">
            <img src="@/assets/images/coin.svg"/>
            <span class="px-1 font-medium">{{ this.user.coins }} GCoins</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-6"></div>
  <h1 class="mb-6 text-2xl font-bold text-white leading-snug">
    Limited Prefixes
  </h1>
  <div class="container ml-auto mr-auto flex flex-wrap items-start">
    <ProductComponent
        :items="items"
        :user="user"
        :creator="creator"
        :authorized="authorized"
        v-for="items in this.limitedItems"
        :key="items.id"
    />
  </div>
  <h1 class="mb-6 text-2xl font-bold text-white leading-snug">
    Prefixes
  </h1>
  <div class="container ml-auto mr-auto flex flex-wrap items-start">
    <ProductComponent
        :items="items"
        :user="user"
        :creator="creator"
        :authorized="authorized"
        v-for="items in this.shopItems"
        :key="items.id"
    />
  </div>
  <h1 class="mb-6 text-2xl font-bold text-white leading-snug">
    EmoteChat Prefixes
  </h1>
  <div class="container ml-auto mr-auto flex flex-wrap items-start">
    <ProductComponent
        :items="items"
        :user="user"
        :creator="creator"
        :authorized="authorized"
        v-for="items in this.emoteChatItems"
        :key="items.id"
    />
  </div>
  <NotificationComponent/>

</template>

<script>
import ProductComponent from "@/components/ProductComponent.vue";
import NotificationComponent from "@/components/NotificationComponent.vue";

export default {
  name: "DashboardComponent",
  components: {ProductComponent, NotificationComponent},
  data: () => ({
    user: {},
    creator: "",
    shopItems: [],
    emoteChatItems: [],
    limitedItems: []
  }),
  props: {
    authorized: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async loadUserInfos() {
      try {
        const response = await fetch("https://api.globalchataddon.de/userinfo", {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
          },
        });
        const json = await response.json();

        this.user.uuid = json.uuid;
        this.user.rank = json.rank;
        this.user.name = json.name;
        this.user.coins = json.coins;
      } catch (error) {
        console.error("Failed to request user infos", error);
      }
    },
    async loadShopItems() {
      try {
        const response = await fetch(
            "https://api.globalchataddon.de/shop/items/buyable"
        );
        const json = await response.json();

        this.limitedItems = json.filter(json => {
          return json.limited === true});

        this.emoteChatItems = json.filter(json => {
          return json.emoteChat === true && json.limited === false}
        );

        this.shopItems = json.filter(json => {
          return json.emoteChat === false && json.limited === false});

      } catch (error) {
        console.error("Failed to request shop infos", error);
      }
    },
  },
  async mounted() {
    this.loadUserInfos();
    this.loadShopItems();
  },
};
</script>

<template>
  <div class="shop">
    <div v-if="authorized">
      <ShopComponent :authorized="true"/>
    </div>
    <div v-else>
      <ShopComponent :authorized="false"/>
      <!--<RegisterComponent/>-->
    </div>
  </div>
</template>

<script>
import ShopComponent from '@/components/ShopComponent.vue'
import {useAuthorization} from "../js/authorization.js"

export default {
  name: 'Shop',
  components: {
    ShopComponent
  },
  data() {
    const {authorized, checkAuthorization, changeAuthorization} = useAuthorization(false);

    return {
      authorized: authorized,
      checkAuthorization: checkAuthorization,
      changeAuthorization: changeAuthorization,
    }
  },
  async mounted() {
    this.checkAuthorization(this.$cookies.get("GlobalChat-Login"))
  },
}
</script>
